/** @format */

import PhoneInput from 'react-phone-input-2';
import { Typography, FormLabel, FormControl, Box, useTheme } from '@mui/material';
import 'react-phone-input-2/lib/style.css';
import '../../../css/global.scss';

const PhoneNumberInputField = (props: any) => {
  const appTheme = useTheme();
  const required = props.uiSchema['ui:required'] || false;
  return (
    <FormControl fullWidth id={props.id} required={props.required} disabled={props.disabled}>
      <FormLabel sx={{ pb: '3px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color={appTheme.palette.primary.light} component='div' sx={{ fontWeight: 600 }}>
            {props.label}
          </Typography>

          {required && (
            <Typography color='error' sx={{ marginLeft: '3px' }}>
              *
            </Typography>
          )}
        </Box>
      </FormLabel>
      <Box className={props.rawErrors && props.rawErrors.length !== 0 ? 'DisplayPhoneError' : 'hidePhoneError'}>
        <PhoneInput
          country={'in'}
          value={props.value}
          onChange={(phone) => props.onChange(phone ? `${phone}` : null)}
          disabled={props.disabled}
          inputStyle={{
            width: '100%',
            border: 'none',
            padding: '24.5px 28.5px 24.5px 45px',
            // borderRadius: '2px',
            // border: props.rawErrors && props.rawErrors.length !== 0 ? '2px solid red' : '0.2px solid #667185',
            backgroundColor: props.disabled ? '#F4F4F4' : '#FFFF',
            color: props.uiSchema['ui:fields'] ? '#9b9c9a' : '#344560',
          }}
          onBlur={(event) => props.onBlur(props.uiSchema['ui:id'], event.target.value)}
        />
      </Box>
    </FormControl>
  );
};

export default PhoneNumberInputField;
