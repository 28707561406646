/** @format */
import * as React from 'react';

// Lib

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import '../../css/AddEditOrganizationUser.scss';

import { OrganizationUserType } from '../../types/OrganizationUsers';
import { OrganizationProductsType } from '../../types/organizations';
import { Container, Typography } from '@mui/material';
import { CustomTableColumnType } from '../../types/CustomTable.types';
import { AddEditModeType } from '../organizations/OrganizationUser';
import CustomTable from '../../components/common/custom-table/CustomTable';
import { convertUrlToFile } from '../../utils/organizations-users';
import { CustomToastContext } from '../../context/CustomToast';
import _ from 'underscore';
import { addUsers, fetchMappedList, updateUsersInfo } from '../../services/users';
import AddUser from './AddUser';

const AddOrganizationUsers: React.FunctionComponent<any> = (props: any) => {
  const [, setIsOpenDeleteProductUser] = React.useState<boolean>(false);
  const { handleAddAlerts } = React.useContext(CustomToastContext);
  const [devicesRowsPerPage, setDevicesRowsPerPage] = React.useState<number>(10);
  const [devicesPageNo, setDevicesPageNo] = React.useState<number>(0);
  const [, setDeleteUserProduct] = React.useState<{ userId: string; productId: string; productName: string } | null>(null);
  const [organizationProducts] = React.useState<OrganizationProductsType[]>([]);
  const [addEditMode, setAddEditMode] = React.useState<AddEditModeType>('ADD');
  const [addEditFormData, setAddEditFormData] = React.useState<any | null>(null);
  const [isOpenAddEditUser, setIsOpenAddEditUser] = React.useState<boolean>(false);
  const [organizationUsers, setOrganizationUsers] = React.useState<any>({ users: [], count: 0 });
  const [disabledFields, setDisabledFields] = React.useState<boolean>(false);
  const [isAddEditLoading, setIsAddEditLoading] = React.useState<boolean>(false);
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const fetchUserDetails = async () => {
    const { rows, count } = await fetchMappedList(devicesRowsPerPage, devicesPageNo * devicesRowsPerPage);

    setOrganizationUsers({ users: rows, count });
  };

  React.useEffect(() => {
    fetchUserDetails();
  }, [devicesRowsPerPage, devicesPageNo]);

  const handleClickAddUser = () => {
    setAddEditMode('ADD');
    setAddEditFormData({} as OrganizationUserType);
    setIsOpenAddEditUser(true);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDevicesPageNo(0);
    setDevicesRowsPerPage(parseInt(event.target.value));
  };

  const handleCloseAddEditUser = () => {
    setAddEditFormData({} as OrganizationUserType);
    setIsOpenAddEditUser(false);
    fetchUserDetails();
  };
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setDevicesPageNo(newPage);
  };
  const handleSubmitAddEditUser = async (formData: any) => {
    console.log('formData=>', formData);
    setIsAddEditLoading(true);
    let UserSign;
    setAddEditFormData({ ...formData });
    // if (formData.adminRolesFlag) {
    //   formData.adminRoles = ['ADMIN'];
    // } else {
    //   formData.adminRoles = [];
    // }
    if (!_.isEmpty(formData?.docSign)) {
      const fileObject = await convertUrlToFile(formData.docSign);
      UserSign = fileObject;
    }

    delete formData.adminRolesFlag;
    delete formData.docSign;

    if (addEditMode === 'ADD') {
      if (_.isEmpty(formData.registrationNumber)) {
        delete formData.registrationNumber;
      }

      try {
        const data = await addUsers(formData, UserSign);

        setOrganizationUsers((prev: any) => prev.users.concat({ ...formData, userId: data.userId, user: { name: formData.name, mobile: formData.mobile, email: formData.email } }));
        handleCloseAddEditUser();
        fetchUserDetails();
        handleAddAlerts({
          code: 'success',
          id: new Date().getTime(),
          message: 'Successfully Added the User',
        });
        setDisabledFields(false);
      } catch (err: any) {
        handleAddAlerts({
          id: new Date().getTime(),
          message: 'Failed Added the Product from Organization',
          code: 'error',
          html: (
            <Typography>
              Failed Added the User <br />
              <Typography variant='fontReg14'>{err?.response?.data?.error}</Typography>
            </Typography>
          ),
        });
      }
    } else {
      try {
        if (_.isEmpty(formData.registrationNumber)) {
          formData.registrationNumber = null;
        }

        const payload: any = {
          name: formData.name,
          email: formData.email,
          qualification: formData.qualification,
          mobile: formData.mobile,
          registrationNumber: formData.registrationNumber,
        };

        await updateUsersInfo(addEditFormData?.userId!, payload, UserSign);
        setOrganizationUsers((prev: any) =>
          prev.users.map((item: any) => {
            if (item.userId === addEditFormData?.userId!) {
              return { ...item, ...payload, user: { ...item.user, registrationNumber: payload.registrationNumber } };
            }
            return { ...item };
          }),
        );
        handleCloseAddEditUser();
        handleAddAlerts({
          code: 'success',
          id: new Date().getTime(),
          message: 'Successfully Updated the User',
        });
      } catch (err: any) {
        handleAddAlerts({
          id: new Date().getTime(),
          message: 'Failed Added the Product from Organization',
          code: 'error',
          html: (
            <Typography>
              Failed Updated the User <br />
              <Typography variant='fontReg14'>{err?.response?.data?.error}</Typography>
            </Typography>
          ),
        });
      }
    }

    setIsAddEditLoading(false);
  };

  const handleClickEditUser = (orgUser: OrganizationUserType) => {
    setAddEditMode('EDIT');
    setDisabledFields(false);
    setAnchorEl(null);

    const payload = JSON.parse(JSON.stringify(orgUser));
    console.log('payload', payload);
    payload.registrationNumber = payload?.registrationNumber ?? '';

    if (payload?.signatureId == null) {
      payload.docSign = '';
    } else {
      payload.docSign = payload?.signatureId;
    }

    setAddEditFormData(payload);
    setIsOpenAddEditUser(true);
  };

  const handleDeleteProductAccess = (index: number) => {
    const { productId } = addEditFormData?.productRoles[index] || {};
    if (_.isEmpty(productId)) {
      console.log('No Product ID');
      return;
    }

    const productInfo = organizationProducts.find((item) => item.productId === productId);

    setDeleteUserProduct({ userId: addEditFormData?.userId!, productId: productId!, productName: productInfo?.product?.productName! });
    setIsOpenDeleteProductUser(true);
  };

  const columns: CustomTableColumnType[] = [
    {
      id: 'user-name',
      label: 'Name',
      cellFormatter: (rowData: OrganizationUserType) => <Typography variant='fontReg20'>{rowData?.name}</Typography>,
    },
    {
      id: 'email',
      label: 'Email',
      cellFormatter: (rowData: OrganizationUserType) => <Typography variant='fontReg20'>{rowData?.email}</Typography>,
    },
    {
      id: 'mobile',
      label: 'Mobile',
      cellFormatter: (rowData: OrganizationUserType) => <Typography variant='fontReg20'>{rowData?.mobile}</Typography>,
    },

    {
      id: 'action',
      cellAlignment: 'right',
      label: (
        <Button sx={{ float: 'right' }} variant='outlined' startIcon={<AddIcon />} size='medium' onClick={handleClickAddUser}>
          <Typography variant='fontReg20'>Add User</Typography>
        </Button>
      ),
      cellFormatter: (rowData: any) => (
        <Button variant='outlined' startIcon={<AddIcon />} size='medium' onClick={() => handleClickEditUser(rowData)}>
          <Typography variant='fontReg20'>Edit</Typography>
        </Button>
      ),
    },
  ];

  return (
    <>
      {isOpenAddEditUser && (
        <AddUser
          setDisabledFields={setDisabledFields}
          disabledFields={disabledFields}
          isApiCalling={isAddEditLoading}
          formData={addEditFormData}
          setAddEditFormData={setAddEditFormData}
          isOpen={isOpenAddEditUser}
          editMode={addEditMode === 'EDIT'}
          organizationProducts={organizationProducts}
          handleClose={handleCloseAddEditUser}
          handleSubmit={handleSubmitAddEditUser}
          handleDeleteProductAccess={handleDeleteProductAccess}
        />
      )}

      <Container maxWidth='lg'>
        <CustomTable
          tablePagination
          count={organizationUsers?.count || 0}
          data={organizationUsers.users}
          columns={columns}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
          rowsPerPageOptions={[10, 15, 20, 30]}
          rowsPerPage={devicesRowsPerPage}
          page={devicesPageNo}
        />
      </Container>
    </>
  );
};

export default AddOrganizationUsers;
