/** @format */

import { Box, FormControl, FormLabel, OutlinedInput, Typography, useTheme } from '@mui/material';

const GridInputField = (props: any) => {
  const appTheme = useTheme();
  const label = props.uiSchema?.['ui:title'] ? props.uiSchema?.['ui:title'] : props.label;
  const required = props.uiSchema['ui:required'] || false;

  return (
    <FormControl required={props.required} sx={{ display: 'flex' }} id={props.id}>
      <FormLabel sx={{ pb: '3px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography color={appTheme.palette.primary.light} component='div' sx={{ fontWeight: 600 }}>
            {label}
          </Typography>
        </Box>
        {required && (
          <Typography color='error' sx={{ marginLeft: '3px' }}>
            *
          </Typography>
        )}
      </FormLabel>

      <OutlinedInput
        fullWidth
        disabled={props.disabled}
        type={props.uiSchema?.['ui:type'] || 'text'}
        value={props.value}
        onChange={(event) => props.onChange(event.target.value)}
        placeholder={props.placeholder}
        error={props.rawErrors && props.rawErrors.length !== 0}
        endAdornment={props.uiSchema['ui:endAdornment'] && <Box sx={{ backgroundColor: props.disabled ? '#F4F4F4' : '#FFFF' }}>{props.uiSchema['ui:endAdornment']}</Box>}
        onBlur={(event) => props.onBlur(props.uiSchema['ui:id'], event.target.value)}
      />
    </FormControl>
  );
};

export default GridInputField;
