/** @format */

import { env } from './../env';

const config = {
  authBaseUrl: env.REACT_APP_AUTH_BASE_URL || '/api',
  UISentryUrl: env.REACT_APP_SENTRY_DSN,
  googleApiKey: 'AIzaSyBdGWMvD0HmMsK1H6_vDzkiCSThyiEetmM',

  prescriptionTemplateBaseUrl: env.REACT_APP_PRESCRIPTION_TEMPLATE_BASE_URL,
  prescriptionTemplateApiKey: env.REACT_APP_PRESCRIPTION_TEMPLATE_API_KEY,

  apiTimeout: 10000,
};
export const TRICOG_CARE_PRODUCT_ID = '3d38b68f-ee3c-49a7-a5ee-faa0a8b97d43';
export default config;
