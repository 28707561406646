/** @format */

import _ from 'underscore';
import { APP_ROLES_SASS_ADMIN } from '../constants/user-role';
import { UserSessionType } from '../PrivateAuth';

export const getUserSession = () => {
  let userSession: UserSessionType = {} as UserSessionType;
  try {
    userSession = JSON.parse(localStorage.getItem('userSession') || '');
  } catch (err) {
    console.log('err', err);
  }
  return userSession;
};

export const checkUserHasPermission = (userRoles: string[]) => {
  const userSession = getUserSession();
  return _.isEmpty(userRoles) || userSession.adminRoles?.some((ele) => userRoles.includes(ele) || ele === APP_ROLES_SASS_ADMIN);
};
