/** @format */

import * as React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import FilterIcon from '@mui/icons-material/Filter';

import { CustomToastAlertsType, CustomToastContext } from '../../../context/CustomToast';
import { appTheme } from '../../../context/AppTheme';
import _ from 'underscore';

const SignatureUploadModel = (props: any) => {
  console.log('props', props);
  const isEdit = props?.uiSchema['ui:fields']?.edit;
  const label = props.uiSchema?.['ui:title'] ? props.uiSchema?.['ui:title'] : props.label;
  const [checkpreviewimg, setCheckPreviewImg]: any = React.useState(null);
  const [signatureId]: any = React.useState(props?.uiSchema['ui:fields']?.formData?.user?.signatureId);
  const fileInputRef: any = React.useRef(null);
  console.log('checkpreviewimg', checkpreviewimg);
  const [open, setOpen] = React.useState(false);
  const [, setCheckPreview] = React.useState(false);
  const [dragging, setDragging] = React.useState(false);

  const { handleAddAlerts } = React.useContext(CustomToastContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCheckPreview(true);
    setOpen(false);
    setDragging(false);
  };

  React.useEffect(() => {
    if (_.isEmpty(props.value)) {
      setCheckPreviewImg(null);
    }
  }, [isEdit, props?.uiSchema, props.value, signatureId]);

  const handleFileUpload = () => {
    const imgFile = fileInputRef.current.files[0];
    const maxSizeBytes = 9437184; // 9 MB in bytes
    if (imgFile && imgFile.size < maxSizeBytes) {
      if (imgFile.type === 'image/png' || imgFile.type === 'image/jpeg' || imgFile.type === 'image/jpg') {
        setCheckPreviewImg(imgFile);
        const imgUrl = URL.createObjectURL(imgFile);
        props.onChange(imgUrl, props.id);
      } else {
        const alerts: CustomToastAlertsType = {
          id: new Date().getTime(),
          code: 'error',
          message: 'Invalid file format. Please drop an image file (JPEG, PNG, JPG) or File too large',
          timeout: 5000,
        };
        handleAddAlerts(alerts);
      }
    }
  };

  const handleDragEnter = (e: any) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e: any) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    setDragging(false);

    const droppedFiles = e.dataTransfer.files;

    const imgFile = droppedFiles[0];
    const maxSizeBytes = 9437184; // 9 MB in bytes
    if (imgFile && imgFile.size < maxSizeBytes) {
      if (imgFile.type === 'image/png' || imgFile.type === 'image/jpeg' || imgFile.type === 'image/jpg') {
        setCheckPreviewImg(imgFile);
        const imgUrl = URL.createObjectURL(imgFile);
        props.onChange(imgUrl, props.id);
      } else {
        const alerts: CustomToastAlertsType = {
          id: new Date().getTime(),
          code: 'error',
          message: 'Invalid file format. Please drop an image file (JPEG, PNG, JPG) or File too large',
          timeout: 5000,
        };
        handleAddAlerts(alerts);
      }
    }
  };

  return (
    <React.Fragment>
      <Box sx={{ gap: '20px', paddingTop: '8px', paddingLeft: '20px' }} id={props.id}>
        <Typography color={appTheme.palette.primary.light} component='div' sx={{ fontWeight: 600, marginBottom: '5px' }}>
          {label}
        </Typography>
        <Button size='large' variant='contained' onClick={handleClickOpen} disabled={props.disabled}>
          Upload
        </Button>
        <Typography noWrap sx={{ maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {checkpreviewimg?.name || signatureId || props?.value}
        </Typography>
      </Box>
      {props.rawErrors?.length !== 0 && <Typography> {props.rawErrors}</Typography>}

      <Dialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            width: '40%',
            height: '45%',
          },
        }}>
        <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'></DialogTitle>
        <IconButton
          aria-label='close'
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '25px',
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            style={{
              border: `2px dashed ${dragging ? 'blue' : 'lightgray'}`,
              padding: '20px',
              borderRadius: '5px',
            }}>
            {checkpreviewimg ? (
              <img
                src={URL.createObjectURL(checkpreviewimg)}
                alt='Preview'
                loading='lazy'
                style={{
                  width: '200px',
                  height: '100px',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <FilterIcon sx={{ fontSize: '100px', color: 'lightgray' }} />
            )}

            <input type='file' id='fileInput' accept='.png, .jpg, .jpeg' style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileUpload} />
            <Button
              variant='outlined'
              sx={{
                color: 'grey',
                fontWeight: '600',
                borderColor: 'lightgray',
              }}
              onClick={() => fileInputRef.current.click()}>
              Add file
            </Button>
            <Typography color='gray'>
              or drag a file to upload
              <br />
              (JPEG, PNG, JPG &lt;10mb)
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          {checkpreviewimg && (
            <Button variant='contained' onClick={handleClose} size='small'>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default SignatureUploadModel;
