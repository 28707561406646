/** @format */

import { RawAxiosRequestConfig } from 'axios';

import AxiosInstance from './index';

import config from '../config/config';

export const fetchAppConfig = async () => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: '/app/config',
  };
  const res = await AxiosInstance(params);
  return res.data || {};
};
