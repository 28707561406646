/** @format */

// Lib
import axios from 'axios';
import Form from '@rjsf/core';
import * as React from 'react';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import validator from '@rjsf/validator-ajv8';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';

// Components

import FieldErrorTemplate from '../../components/common/react-json-schema/FieldErrorTemplate';
import GridSelectInputField from '../../components/common/react-json-schema/GridSelectInputField';
import PhoneNumberInputField from '../../components/common/react-json-schema/PhoneNumberInputField';

import GridTemplateObjectFieldTemplate from '../../components/common/react-json-schema/GridObjectFieldTemplate';

// Types
import type { RJSFSchema } from '@rjsf/utils';

import '../../css/AddEditOrganizationUser.scss';
import GridInputField from '../../components/common/react-json-schema/GridInputField';
import { QUALIFICATION_OPTIONS } from '../../constants/AddEditUser';
import { OrganizationUserType } from '../../types/OrganizationUsers';
import { OrganizationProductsType } from '../../types/organizations';
import { OrgSaveSchema } from '../../components/organizations/organizations-user/validation.joi';
import CustomAsyncSelect from '../../components/common/custom-select/CustomAsyncSelect';
import { fetchAllSearchedUsers } from '../../services/organizations';
import SignatureUploadModel from '../../components/organizations/organizations-user/SignatureUploadModel';

interface AddEditUserProps {
  setDisabledFields: React.Dispatch<React.SetStateAction<boolean>>;
  disabledFields: boolean;
  editMode: boolean;
  setAddEditFormData: React.Dispatch<React.SetStateAction<any | null>>;
  isApiCalling: boolean;

  formData: OrganizationUserType;

  organizationProducts: OrganizationProductsType[];

  isOpen: boolean;

  handleClose: () => void;

  handleSubmit: (formData: any) => void;
  handleDeleteProductAccess: (index: any) => void;
}

const AddUser: React.FunctionComponent<AddEditUserProps> = (props) => {
  const [inputvalue, setInputvalue] = React.useState<string>('');
  const axiosSourceRef: any = React.useRef();
  const formRef: any = React.useRef(null);
  const formDataRef: any = React.useRef({});
  const buttonRef: any = React.useRef();
  const formDataErrorsRef = React.useRef<any>({});

  // const { handleAddAlerts } = React.useContext(CustomToastContext);

  const onChangeForm = (data: any, id: string | undefined) => {
    formDataRef.current = data;
  };

  const schema: RJSFSchema = {
    type: 'object',
    title: props.editMode ? 'Edit User' : 'Add User',
    properties: {
      name: {
        type: 'string',
        title: 'Name',
      },
      mobile: {
        type: 'string',
        title: 'Phone Number',
      },
      email: {
        type: 'string',
        title: 'Email',
      },
      qualification: {
        type: 'string',
        title: 'Qualification',
      },

      docSign: {
        type: 'string',
        title: 'Signature',
      },

      registrationNumber: {
        type: 'string',
        title: 'Registration Number',
      },
    },
  };
  const uiSchema: any = {
    'ui:ObjectFieldTemplate': GridTemplateObjectFieldTemplate,

    'ui:fields': {
      className: 'users',
      closeDialogBox: () => handleClose(),
    },

    name: {
      'ui:widget': GridInputField,
      'ui:label': '',
      'ui:grid-area': 'name',
      // 'ui:disabled': props.editMode || props.disabledFields,
      'ui:required': true,
    },

    qualification: {
      'ui:widget': GridSelectInputField,
      'ui:label': '',
      'ui:grid-area': 'qualification',

      'ui:customOptions': QUALIFICATION_OPTIONS,
      // 'ui:disabled': props.editMode || props.disabledFields,
      'ui:required': true,
    },

    mobile: {
      'ui:widget': PhoneNumberInputField,
      'ui:id': 'mobile',
      'ui:label': '',
      'ui:grid-area': 'mobile',
      // 'ui:disabled': props.editMode || props.disabledFields,
      'ui:required': true,
    },

    email: {
      'ui:widget': GridInputField,
      'ui:id': 'email',
      'ui:label': '',
      'ui:grid-area': 'email',
      // 'ui:disabled': props.editMode || props.disabledFields,
      'ui:required': true,
    },

    docSign: {
      'ui:widget': SignatureUploadModel,
      'ui:label': '',
      'ui:id': 'docSign',
      'ui:grid-area': 'docSign',
      'ui:fields': {
        formData: props.formData,
      },
    },

    registrationNumber: {
      'ui:widget': GridInputField,
      'ui:label': '',
      'ui:grid-area': 'registrationNumber',
      'ui:id': 'registrationNumber',
      // 'ui:disabled': !showDoctor,
    },

    adminRolesFlag: {
      'ui:widget': () => null,
      'ui:label': '',
      'ui:grid-area': 'admin-roles',
    },
  };

  const handleClose = () => {
    props.handleClose();
    props.setDisabledFields(false);
  };

  const handleSubmit = async (formData: any) => {
    props.handleSubmit(formData.formData);
  };

  const customValidate = (formData: any, errors: any) => {
    let schema = OrgSaveSchema('EDIT');
    if (buttonRef?.current?.click === 'save') {
      schema = OrgSaveSchema('SAVE');
    }

    const errorSchema = schema.validate(formData, { abortEarly: false, allowUnknown: true });
    if (errorSchema && errorSchema.error) {
      let firstErrorPath = '';
      errorSchema.error?.details.forEach((item: any) => {
        if (!firstErrorPath) {
          firstErrorPath = `root_${item.path.join('_')}`;
        }
        const path = lodash.get(errors, [...item.path]);
        if (_.isEmpty(path?.__errors)) {
          path?.addError(item?.message);
        }
      });
    }

    if (formDataErrorsRef.current?.mobile) {
      errors.mobile.addError(formDataErrorsRef.current?.mobile);
    }

    if (formDataErrorsRef.current?.email) {
      errors.email.addError(formDataErrorsRef.current?.email);
    }

    if (formDataErrorsRef.current?.registrationNumber) {
      errors.registrationNumber.addError(formDataErrorsRef.current?.registrationNumber);
    }

    return errors;
  };

  const transformErrors = (errors: any[]) => {
    return errors?.map((error) => {
      if (error.stack.includes('Registration Number')) {
        error.message = `Registration Number is Required`;
      }
      if (error.stack.includes('then')) {
        error.message = ``;
      }
      return error;
    });
  };

  const handleInputChange = (value: string) => {
    setInputvalue(value);
  };

  const fetchData = (inputValue: string, cb: any) => {
    if (axiosSourceRef.current && axiosSourceRef.current.token) {
      axiosSourceRef.current.cancel();
    }

    axiosSourceRef.current = axios.CancelToken.source();

    if (inputValue && !_.isEmpty(inputValue.trim())) {
      fetchAllSearchedUsers(inputValue, axiosSourceRef.current)
        .then((data: any) => {
          data[0]?.org_users?.shift();
          cb(data);
        })
        .catch((err: any) => {
          console.error('err', err);
        });
    } else {
      cb();
    }
  };

  const handleOnchange = (payload: any) => {
    let docSign = '';

    if (payload?.signatureId == null) {
      docSign = '';
    } else {
      docSign = payload?.signatureId;
    }
    props.setAddEditFormData({
      name: payload?.name,
      email: payload?.email,
      mobile: payload?.mobile,
      userId: payload?.userId,
      docSign,
      registrationNumber: payload?.registrationNumber || undefined,
      qualification: payload?.qualification,
    });

    props.setDisabledFields(true);
  };

  return (
    <Dialog
      maxWidth='md'
      fullWidth
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={props.isOpen}
      id='add-edit-organiations-user'
      PaperProps={{
        sx: {
          p: props.editMode ? '0px 10px 10px 10px' : '20px 10px 10px 10px',
        },
      }}>
      {props.editMode && (
        <Box sx={{ maxWidth: '400px', position: 'relative', left: '30%', top: '50px' }}>
          <CustomAsyncSelect
            value={inputvalue}
            placeholder={'Search users by email or Phone Number'}
            loadOptions={fetchData}
            components={{ DropdownIndicator: () => null, IndicatorSeparator: () => <SearchIcon /> }}
            onInputChange={handleInputChange}
            onChange={(e: any) => handleOnchange(e)}
            getOptionLabel={(e: any) => `${e.name} ` + ' - ' + e.mobile}
            getOptionValue={(e: any) => e.userId}
          />
        </Box>
      )}

      <Form
        schema={schema}
        showErrorList={false}
        liveValidate
        ref={formRef}
        onChange={(data: any, id) => {
          onChangeForm(data, id);
        }}
        onError={(errors: any) => {
          console.log('errors', errors);
        }}
        customValidate={customValidate}
        transformErrors={transformErrors}
        formData={props.formData}
        templates={{ FieldErrorTemplate }}
        onSubmit={handleSubmit}
        uiSchema={uiSchema}
        validator={validator}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', paddingBottom: '20px' }}>
          <Button type='reset' color='error' sx={{ marginLeft: '10px' }} onClick={handleClose}>
            Cancel
          </Button>
          <Button
            ref={buttonRef}
            onClick={() => {
              if (buttonRef.current) {
                buttonRef.current.click = 'save';
              }
            }}
            type='submit'
            variant='contained'
            color='primary'
            sx={{ marginInline: '10px' }}
            disabled={props.isApiCalling}>
            {!props.isApiCalling && 'Submit'}
            {props.isApiCalling && <CircularProgress />}
          </Button>
        </Box>
      </Form>
    </Dialog>
  );
};

export default AddUser;
