/** @format */

// Library
import { lazy, Suspense } from 'react';

// Types
import { RouteObject } from 'react-router-dom';

// Components
import Auth from './PrivateAuth';

import CustomLoading from './components/common/custom-loading/CustomLoading';
import UserPermissionPage from './components/common/user-permission/UserPermissionPage';
import { APP_ROLES_INVENTORY_MANAGEMENT, APP_ROLES_ORGANIZATION_MANAGEMENT, APP_ROLES_SASS_ADMIN, APP_ROLES_USER_MANAGEMENT } from './constants/user-role';
import OrganizationProvider from './context/OrganizationContext';
import AddOrganizationUsers from './pages/users/AddOrganizationUser';

const Layout = lazy(() => import('./components/layout/Layout'));

const Login = lazy(() => import('./pages/login/Login'));
const LoginSuccess = lazy(() => import('./pages/login/LoginSuccess'));

const AdminUsersPage = lazy(() => import('./pages/admin-users/AdminUsers'));
const AssetsPage = lazy(() => import('./pages/assets/Assets'));
const OrganizationsPage = lazy(() => import('./pages/organizations/Organizations'));

// named as OrganizationsPage
const OrganizationUserPage = lazy(() => import('./pages/organizations/OrganizationUser'));
const OrganizationInfoPage = lazy(() => import('./pages/organizations/OrganizationInfo'));
const OrganizationProductsPage = lazy(() => import('./pages/organizations/OrganizationProducts'));
const AddOrganizationAssetsPage = lazy(() => import('./pages/organizations/OrganizationAssets'));
const OrganizationLogsPage = lazy(() => import('./pages/organizations/OrganizationLogs'));
const OrganizationAssetsPage = lazy(() => import('./pages/organizations/OrganizationAssets'));
const OrganizationSettingsPage = lazy(() => import('./pages/organizations/OrganizationSettings'));
const OrganizationProfilePage = lazy(() => import('./pages/organizations/OrganizationProfile'));

const SystemSettingsPage = lazy(() => import('./pages/system-settings/SystemSettings'));

const OrganizationPdfTemplates = lazy(() => import('./pages/organizations/OrganizationPdfTemplates'));
const PdfTemplatesPage = lazy(() => import('./pages/pdf-templates/PdfTemplates'));
const Page404 = lazy(() => import('./pages/Page404'));
const ErroredPage = lazy(() => import('./pages/ErroredPage'));
const NoAccessPage = lazy(() => import('./pages/NoAccessPage'));

export const NavRoutes: RouteObject[] = [
  {
    path: '/login',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/error',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <ErroredPage />
      </Suspense>
    ),
  },
  {
    path: '/login/success',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <LoginSuccess />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: (
      <Suspense fallback={<CustomLoading />}>
        <Auth>
          <Layout />
        </Auth>
      </Suspense>
    ),
    children: [
      {
        path: '/organizations',
        children: [
          {
            path: '/organizations',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <OrganizationsPage />
              </Suspense>
            ),
          },
          {
            path: '/organizations/:organizationId',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <OrganizationProvider>
                  <OrganizationProfilePage />
                </OrganizationProvider>
              </Suspense>
            ),
            children: [
              {
                path: 'products',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_ORGANIZATION_MANAGEMENT]}>
                      <OrganizationProductsPage />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
              {
                index: true,
                path: 'info',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <OrganizationInfoPage />
                  </Suspense>
                ),
              },
              {
                path: 'logs',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <OrganizationLogsPage />
                  </Suspense>
                ),
              },
              {
                path: 'users',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_USER_MANAGEMENT]}>
                      <OrganizationUserPage />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
              {
                path: 'assets',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_INVENTORY_MANAGEMENT]}>
                      <OrganizationAssetsPage />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
              {
                path: 'settings',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_INVENTORY_MANAGEMENT]}>
                      <OrganizationSettingsPage />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
              {
                path: 'pdfTemplates',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <UserPermissionPage roles={[APP_ROLES_INVENTORY_MANAGEMENT]}>
                      <OrganizationPdfTemplates />
                    </UserPermissionPage>
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '/admin/users',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_SASS_ADMIN]}>
              <AdminUsersPage />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/settings',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_SASS_ADMIN]}>
              <SystemSettingsPage />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/pdf-templates',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_SASS_ADMIN]}>
              <PdfTemplatesPage />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/users',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <UserPermissionPage roles={[APP_ROLES_SASS_ADMIN]}>
              <AddOrganizationUsers />
            </UserPermissionPage>
          </Suspense>
        ),
      },
      {
        path: '/assets',
        element: (
          <UserPermissionPage roles={[APP_ROLES_INVENTORY_MANAGEMENT]}>
            <Suspense fallback={<CustomLoading />}>
              <AssetsPage />
            </Suspense>
          </UserPermissionPage>
        ),
        children: [
          {
            path: 'weight',
            element: (
              <Suspense fallback={<CustomLoading />}>
                <OrganizationLogsPage />
              </Suspense>
            ),
            children: [
              {
                path: 'bp',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <AddOrganizationAssetsPage />
                  </Suspense>
                ),
              },
              {
                path: 'applePhone',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <AddOrganizationAssetsPage />
                  </Suspense>
                ),
              },
              {
                path: 'weight',
                element: (
                  <Suspense fallback={<CustomLoading />}>
                    <AddOrganizationAssetsPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: '/no-access',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <NoAccessPage />
          </Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <Suspense fallback={<CustomLoading />}>
            <Page404 />
          </Suspense>
        ),
      },
    ],
  },
];
