/** @format */

import { RawAxiosRequestConfig } from 'axios';

import AxiosInstance from './index';

import config from '../config/config';

export const fetchUserSession = async (sessionId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: '/loggedIn',
    headers: {
      'x-api-key': sessionId,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};
