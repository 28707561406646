/** @format */

import { FormControl, FormLabel, MenuItem, Select, Typography, useTheme } from '@mui/material';
import { DropdownOptionType } from '../../../types/CustomForm.types';
import React from 'react';

const GridSelectInputField = (props: any) => {
  const appTheme = useTheme();
  const [value, setValue] = React.useState<string>('');

  let options: any[] = [];
  const required = props.uiSchema['ui:required'] || false;
  if (props.uiSchema['ui:customOptions']) {
    options = props.uiSchema['ui:customOptions'];
  } else {
    options = props.options.enumOptions;
  }

  React.useEffect(() => {
    if (props.value) {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <FormControl
      sx={{
        width: '100%',
        cursor: props.readonly ? 'no-drop !important' : '',
      }}
      id={props.id}
      required={props.required}
      disabled={props.uiSchema['ui:disabled'] || false}>
      <FormLabel sx={{ pb: '3px', marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
        <Typography color={appTheme.palette.primary.light} component='div' sx={{ fontWeight: 600 }}>
          {props.label}
        </Typography>
        {required && (
          <Typography color='error' sx={{ marginLeft: '3px' }}>
            *
          </Typography>
        )}
      </FormLabel>

      <Select
        fullWidth
        inputProps={{
          tabIndex: props.uiSchema?.['ui:tabIndex'],
          sx: {
            p: '12px 12px 14px 12px',
          },
        }}
        readOnly={props.readonly}
        disabled={props.disabled}
        value={value}
        tabIndex={props.uiSchema?.['ui:tabIndex']}
        // defaultValue={props.options?.enumOptions.find((e) => e.includes(props.value))}
        onChange={(event: any) => props.onChange(event.target.value)}
        error={!!(props.rawErrors && props.rawErrors.length !== 0)}>
        {options.map((e: DropdownOptionType) => {
          return (
            <MenuItem value={e.value} key={e.value}>
              {e.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default GridSelectInputField;
