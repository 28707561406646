/** @format */

import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { UserSessionContext } from './context/UserSession';

import AxiosInstance from './services/index';
import { fetchUserSession } from './services/user-session';
import { getUserSession } from './utils/user-session';

interface InitialComponentProps {}

const InitialComponent: React.FunctionComponent<InitialComponentProps> = () => {
  const navigate = useNavigate();

  const { userSession, handleClearUserSession, handleUpdateUserSession } = React.useContext(UserSessionContext);

  React.useEffect(() => {
    AxiosInstance.interceptors.request.use(
      (req) => {
        if (userSession.sessionId) {
          req.headers['x-api-key'] = userSession.sessionId;
        }
        return req;
      },
      (error) => {
        console.error('ERROR in axios interceptor', error);
        return Promise.reject(error);
      },
    );

    AxiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        console.log('status', error?.response);

        if (error && error.response && error?.response?.status === 401) {
          handleClearUserSession();
          navigate('login');
        }

        return Promise.reject(error);
      },
    );
  }, [userSession]);

  const getUserSessionAPi = async (sessionId: string) => {
    const user = await fetchUserSession(sessionId);
    localStorage.setItem('userSession', JSON.stringify({ ...user, sessionId }));
    handleUpdateUserSession({ ...user, sessionId });
  };

  React.useEffect(() => {
    const userSession = getUserSession();
    if (userSession && userSession.sessionId) {
      getUserSessionAPi(userSession.sessionId);
    }
  }, []);

  return null;
};

export default InitialComponent;
