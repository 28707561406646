/** @format */

// Library
import * as React from 'react';
import { HashRouter, useRoutes } from 'react-router-dom';

import { ThemeProvider } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

// Css
import './css/global.scss';

// App Routes
import { NavRoutes } from './_navigation.routes';

// App Theme
import { appTheme } from './context/AppTheme';

import InitialComponent from './InitialComponent';

import CustomToastProvider from './context/CustomToast';
import UserSessionProvider from './context/UserSession';
import AppConfigProvider from './context/AppConfigContext';

interface AppProps {}

const HomeApp: React.FunctionComponent<AppProps> = () => {
  return useRoutes(NavRoutes);
};

const AppWrapper: React.FunctionComponent<AppProps> = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <UserSessionProvider>
          <CustomToastProvider>
            <AppConfigProvider>
              <HashRouter>
                <InitialComponent />
                <HomeApp />
              </HashRouter>
            </AppConfigProvider>
          </CustomToastProvider>
        </UserSessionProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default AppWrapper;
