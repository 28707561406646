/** @format */

// Lib
import * as React from 'react';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import TablePagination from '@mui/material/TablePagination';
import TableContainer from '@mui/material/TableContainer';

// Types
import { CustomTableColumnType } from '../../../types/CustomTable.types';

interface CustomTableProps {
  tablePagination?: boolean;
  count?: number;
  page?: number;
  handleChangePage?: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  columns: CustomTableColumnType[];
  rowsPerPage?: number;
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  data: unknown[];
  rowsPerPageOptions?: number[];

  noDataMessage?: string;
}

const CustomTable: React.FunctionComponent<CustomTableProps> = (props) => {
  const appTheme = useTheme();
  const _renderTableHeadColumn = (column: CustomTableColumnType) => {
    if (column.hiddenColumn || column.hiddenHeader) {
      return null;
    }

    return (
      <TableCell align={column.labelAlignment || 'inherit'} colSpan={column.headerColSpan || 1} key={column.id} className={`table-head-cell-${column.id}`} variant='head'>
        <Typography variant='fontReg18' color={appTheme.palette.common.black}>
          {column.label}
        </Typography>
      </TableCell>
    );
  };

  const _renderTableBodyColumn = (rowData: any, column: CustomTableColumnType, index: number) => {
    if (!rowData || column.hiddenColumn) return null;

    let value: JSX.Element | null = null;

    if (column.value) {
      value = rowData[column.value];
    }

    if (column.cellFormatter) {
      value = column.cellFormatter(rowData, index);
    }

    if (column.valueFormatter) {
      value = column.valueFormatter(rowData);
    }

    return (
      <TableCell key={column.id} style={{ minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth }} className={`table-body-cell-${column.id}`} align={column.cellAlignment}>
        {value}
      </TableCell>
    );
  };

  return (
    <Box className='custom-table'>
      <TableContainer className='table-container' component={Paper}>
        <Table stickyHeader aria-labelledby='tableTitle' className='table'>
          <TableHead className='table-head'>
            <TableRow className='table-head-row'>{props.columns.map(_renderTableHeadColumn)}</TableRow>
          </TableHead>
          {
            <TableBody className='table-body'>
              {props.count === 0 ? (
                <TableCell colSpan={props.columns.length}>
                  <Typography align='center'>{props.noDataMessage}</Typography>
                </TableCell>
              ) : (
                props.data?.map((rowData: any, index: number) => (
                  <TableRow key={index} className='table-body-row'>
                    {props.columns.map((column: any) => _renderTableBodyColumn(rowData, column, index))}
                  </TableRow>
                ))
              )}
            </TableBody>
          }
        </Table>
      </TableContainer>

      <Box display='flex' alignItems='center' justifyContent='flex-end' bgcolor='#fafbfc'>
        {props?.tablePagination && props.rowsPerPage && (
          <TablePagination
            className='table-pagination'
            classes={{ actions: 'pagination-actions', displayedRows: 'pagination-text' }}
            rowsPerPageOptions={props.rowsPerPageOptions}
            component='div'
            count={props.count!}
            rowsPerPage={props.rowsPerPage}
            page={props.page!}
            onPageChange={props.handleChangePage!}
            onRowsPerPageChange={props.handleChangeRowsPerPage!}
          />
        )}
      </Box>
    </Box>
  );
};

CustomTable.defaultProps = {
  noDataMessage: 'No Data to Display',
  rowsPerPage: 20,
  rowsPerPageOptions: [10, 15, 20],
};

export default CustomTable;
