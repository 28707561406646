/** @format */

import { Box, Grid, IconButton, Typography } from '@mui/material';
import { ObjectFieldTemplateProps } from 'react-jsonschema-form';
import CloseIcon from '@mui/icons-material/Close';

const GridTemplateObjectFieldTemplate: React.FunctionComponent<ObjectFieldTemplateProps> = (props) => {
  // let editMode: boolean = props.uiSchema['ui:fields']?.disabled;
  // let editCard = props.uiSchema['ui:fields']?.handleClick;
  const customRef = props.uiSchema['ui:fields']?.customRef;

  return (
    <Box id={props.uiSchema['ui:fields']?.id} ref={customRef} sx={{ '& > div': { pb: '0px' } }}>
      {props.title && (
        <Box sx={{ p: '0px 5px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant='fontBold28' color={'primary'} sx={{ marginBottom: '20px', fontWeight: 600, padding: '10px 15px' }}>
            {props.title}
          </Typography>

          <IconButton className={props?.description === 'patient-details' ? 'hideCloseIcon' : 'showCloseIcon'} size='small' onClick={props.uiSchema['ui:fields']?.closeDialogBox}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}

      <Grid id={`grid-template-areas-${props.uiSchema['ui:fields']?.className}`} container sx={{ display: 'grid', gridColumnGap: '0.625rem', gridRowGap: '0rem', padding: '5px 10px' }}>
        {props.properties.map((element, idx) => (
          <Grid item sx={{ gridArea: element.content?.props?.uiSchema?.['ui:grid-area'] }} key={idx}>
            {element.content}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default GridTemplateObjectFieldTemplate;
