/** @format */

import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrganizaitonDataExportRequest, OrganizationItemType } from '../types/organizations';
import { CustomToastAlertsType, CustomToastContext } from './CustomToast';
import {
  deleteOrganization,
  deleteOrganizationPortalData,
  createRequestTOexportAllOrganizationPortalData,
  fetchOrganizationDetails,
  updateOrganization,
  getAllexportOrganizationPortalData,
} from '../services/organizations';
import { convertUrlToFile } from '../utils/organizations-users';
import _ from 'underscore';

export interface OrganizationProviderContextType {
  openEditOrganizationDialog: boolean;
  handleClickOpenEditOrganizationDialog: () => void;
  handleCloseEditOrganizationDialog: () => void;

  isOrgLoading: boolean;
  orgDetails: OrganizationItemType;
  handleClickUpdateOrganization: any;

  deactivateOrgIsLoading: boolean;
  showDeactivateOrgDialog: boolean;
  handleClickShowDeactivateOrgDialog: () => void;
  handleClickHideDeactivateOrgDialog: () => void;
  handleSubmitDeactivateOrganization: any;

  deletePortalDataIsLoading: boolean;
  showDeletePortalDataDialog: boolean;
  handleClickShowDeletePortalDataDialog: () => void;
  handleClickHideDeletePortalDataDialog: () => void;
  handleSubmitDeletePortalData: any;

  exportPortalDataIsLoading: boolean;
  organizationDataExportRequests: OrganizaitonDataExportRequest[];
  handleSubmitExportPortalData: () => void;
}

const defaultValue: OrganizationProviderContextType = {
  openEditOrganizationDialog: false,
  handleClickOpenEditOrganizationDialog: console.log,
  handleCloseEditOrganizationDialog: console.log,

  isOrgLoading: false,
  orgDetails: {} as OrganizationItemType,
  handleClickUpdateOrganization: console.log,

  deactivateOrgIsLoading: false,
  showDeactivateOrgDialog: false,
  handleClickShowDeactivateOrgDialog: console.log,
  handleClickHideDeactivateOrgDialog: console.log,
  handleSubmitDeactivateOrganization: console.log,

  deletePortalDataIsLoading: false,
  showDeletePortalDataDialog: false,
  handleClickShowDeletePortalDataDialog: console.log,
  handleClickHideDeletePortalDataDialog: console.log,
  handleSubmitDeletePortalData: console.log,

  exportPortalDataIsLoading: false,
  organizationDataExportRequests: [],
  handleSubmitExportPortalData: console.log,
};

export const OrganizationContext = React.createContext(defaultValue);

interface OrganizationProviderProps {
  children: any;
}

const OrganizationProvider: React.FC<OrganizationProviderProps> = (props) => {
  const { handleAddAlerts } = React.useContext(CustomToastContext);
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const [openEditOrganizationDialog, setOpenEditOrganizationDialog] = React.useState<boolean>(false);
  const [updateIsLoading, setUpdateIsLoading] = React.useState<boolean>(false);
  const [isOrgLoading, setIsOrgLoading] = React.useState<boolean>(true);
  const [orgDetails, setOrgDetails] = React.useState<OrganizationItemType>({} as OrganizationItemType);
  const [organizationDataExportRequests, setOrganizationDataExportRequests] = React.useState<OrganizaitonDataExportRequest[]>([]);
  const [deactivateOrgIsLoading, setDeleteOrgIsLoading] = React.useState<boolean>(false);
  const [showDeactivateOrgDialog, setShowDeactivateOrgDialog] = React.useState<boolean>(false);

  const [showDeletePortalDataDialog, setShowDeletePortalDataDialog] = React.useState<boolean>(false);
  const [deletePortalDataIsLoading, setDeletePortalDataIsLoading] = React.useState<boolean>(false);

  const [exportPortalDataIsLoading, setExportPortalDataIsLoading] = React.useState<boolean>(false);

  const handleClickShowDeletePortalDataDialog = () => {
    setShowDeletePortalDataDialog(true);
  };

  const handleClickHideDeletePortalDataDialog = () => {
    setShowDeletePortalDataDialog(false);
  };

  const handleClickShowDeactivateOrgDialog = () => {
    setShowDeactivateOrgDialog(true);
  };

  const handleClickHideDeactivateOrgDialog = () => {
    setShowDeactivateOrgDialog(false);
  };

  const handleClickOpenEditOrganizationDialog = () => {
    setOpenEditOrganizationDialog(true);
  };

  const handleCloseEditOrganizationDialog = () => {
    setOpenEditOrganizationDialog(false);
  };

  const getOrganizationDetails = React.useCallback(async () => {
    setIsOrgLoading(true);
    try {
      const ps = [];
      ps.push(fetchOrganizationDetails(organizationId!));
      ps.push(getAllexportOrganizationPortalData(organizationId!));
      const [orgDetails, dataRequests] = await Promise.all(ps);
      if (orgDetails.logoId == null) {
        orgDetails.logo = '';
      } else {
        orgDetails.logo = orgDetails.logoId;
      }
      delete orgDetails.logoId;
      setOrgDetails(orgDetails);
      setOrganizationDataExportRequests(dataRequests);
    } catch (err) {
      navigate('/error');
    }
    setIsOrgLoading(false);
  }, [navigate, organizationId]);

  React.useEffect(() => {
    setOrgDetails({} as OrganizationItemType);
    getOrganizationDetails();
  }, [getOrganizationDetails]);

  const handleSubmitDeactivateOrganization = React.useCallback(async () => {
    if (deactivateOrgIsLoading) {
      return;
    }

    setDeleteOrgIsLoading(true);

    try {
      await deleteOrganization(organizationId!);

      setOrgDetails((prev) => ({ ...prev, deactivatedAt: new Date() }));

      setDeleteOrgIsLoading(false);
      setShowDeactivateOrgDialog(false);

      const alerts: CustomToastAlertsType = {
        id: new Date().getTime(),
        code: 'success',
        message: 'Successfully deactivated organization',
        timeout: 5000,
      };

      setOrgDetails({
        ...orgDetails,
        status: 'DEACTIVATED',
      });

      handleAddAlerts(alerts);
    } catch (error) {
      const alerts: CustomToastAlertsType = {
        id: new Date().getTime(),
        code: 'error',
        message: 'Failed to update organization',
        timeout: 5000,
      };
      handleAddAlerts(alerts);
      setDeleteOrgIsLoading(false);
    }
  }, [deactivateOrgIsLoading, handleAddAlerts, orgDetails, organizationId]);

  const handleSubmitDeletePortalData = async () => {
    if (deletePortalDataIsLoading) {
      return;
    }
    setDeletePortalDataIsLoading(true);
    try {
      await deleteOrganizationPortalData(organizationId!);

      setOrgDetails((prev) => ({ ...prev, portalDataDeletedAt: new Date().toString() }));

      const alerts: CustomToastAlertsType = {
        id: new Date().getTime(),
        code: 'success',
        message: 'Successfully deleted user data',
        timeout: 5000,
      };
      setShowDeletePortalDataDialog(false);
      handleAddAlerts(alerts);
    } catch (err) {
      const alerts: CustomToastAlertsType = {
        id: new Date().getTime(),
        code: 'error',
        message: 'Failed to delete user data',
        timeout: 5000,
      };
      handleAddAlerts(alerts);
    }
    setDeletePortalDataIsLoading(false);
  };

  const handleClickUpdateOrganization = React.useCallback(
    async (payload: OrganizationItemType) => {
      if (updateIsLoading) {
        return;
      }
      let orgLogo;
      if (!_.isEmpty(payload.logo)) {
        const fileObject = await convertUrlToFile(payload.logo);
        orgLogo = fileObject;
      }
      delete payload.logo;

      setUpdateIsLoading(true);

      try {
        await updateOrganization(payload, organizationId!, orgLogo);

        const alerts: CustomToastAlertsType = {
          id: new Date().getTime(),
          code: 'success',
          message: 'Successfully Updated organization',
          timeout: 5000,
        };

        handleAddAlerts(alerts);

        setOrgDetails((prev) => ({ ...prev, ...payload }));

        setUpdateIsLoading(false);
        setOpenEditOrganizationDialog(false);
        getOrganizationDetails();
      } catch (error) {
        console.log(error);
        const alerts: CustomToastAlertsType = {
          id: new Date().getTime(),
          code: 'error',
          message: 'Failed to update organization',
          timeout: 5000,
        };

        setUpdateIsLoading(false);
        handleAddAlerts(alerts);
      }
    },
    [handleAddAlerts, organizationId, updateIsLoading],
  );

  const handleSubmitExportPortalData = async () => {
    if (deletePortalDataIsLoading) {
      return;
    }
    setExportPortalDataIsLoading(true);
    try {
      await createRequestTOexportAllOrganizationPortalData(organizationId!);
      const alerts: CustomToastAlertsType = {
        id: new Date().getTime(),
        code: 'success',
        message: 'Successfully started the exporting user data',
        timeout: 5000,
      };
      handleAddAlerts(alerts);
    } catch (err) {
      const alerts: CustomToastAlertsType = {
        id: new Date().getTime(),
        code: 'error',
        message: 'Failed to delete user data',
        timeout: 5000,
      };
      handleAddAlerts(alerts);
    }
    setExportPortalDataIsLoading(false);
  };

  const value: OrganizationProviderContextType = {
    openEditOrganizationDialog,
    isOrgLoading,
    orgDetails,
    handleClickOpenEditOrganizationDialog,
    handleCloseEditOrganizationDialog,
    handleClickUpdateOrganization,

    deactivateOrgIsLoading,
    showDeactivateOrgDialog,
    handleClickShowDeactivateOrgDialog,
    handleClickHideDeactivateOrgDialog,
    handleSubmitDeactivateOrganization,

    deletePortalDataIsLoading,
    showDeletePortalDataDialog,
    handleClickShowDeletePortalDataDialog,
    handleClickHideDeletePortalDataDialog,
    handleSubmitDeletePortalData,

    exportPortalDataIsLoading,
    organizationDataExportRequests,
    handleSubmitExportPortalData,
  };

  return <OrganizationContext.Provider value={value}>{props.children}</OrganizationContext.Provider>;
};

export default OrganizationProvider;
