/** @format */

import _ from 'underscore';
import * as React from 'react';

import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

import { CustomToastAlertsType, CustomToastContext } from '../../../context/CustomToast';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

interface CustomToastProps {}

const CustomToast: React.FC<CustomToastProps> = (props) => {
  const { handleCloseAlerts, alerts } = React.useContext(CustomToastContext);

  const hideToast = (alert: CustomToastAlertsType) => {
    handleCloseAlerts(alert.id);
  };

  const _renderAlertMessage = (alerts: CustomToastAlertsType, index: number) => {
    const handleClickClearToast = () => {
      hideToast(alerts);
    };

    if (!alerts.noTimeout) {
      setTimeout(handleClickClearToast, alerts.timeout || 4000);
    }

    let content = alerts.message;

    if (alerts.html) {
      content = alerts.html;
    }

    return (
      <Grid key={index} item xs={12}>
        <Alert
          variant='outlined'
          severity={alerts.code as AlertColor}
          sx={{ width: '100%', bgcolor: 'white', display: 'flex', alignItems: 'center', maxWidth: '35rem' }}
          onClose={handleClickClearToast}>
          {content}
        </Alert>
      </Grid>
    );
  };

  let uniqMessage = alerts;

  if (alerts.length > 0) {
    uniqMessage = _.uniq(uniqMessage, (item) => item.message);
  }

  uniqMessage = uniqMessage.slice(0, 3);

  return (
    <Snackbar open={uniqMessage.length > 0}>
      <Grid container spacing={2} sx={{ flexDirection: 'column' }}>
        {uniqMessage.map(_renderAlertMessage)}
      </Grid>
    </Snackbar>
  );
};

export default CustomToast;
