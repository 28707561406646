/** @format */

import * as React from 'react';

import { AlertColor } from '@mui/material';
import CustomToast from '../components/common/custom-toast/CustomToast';

export interface CustomToastAlertsType {
  message: string;
  id: number;
  code: AlertColor;
  timeout?: number;
  noTimeout?: boolean;
  html?: any;
}

export interface CustomToastProviderContextType {
  alerts: CustomToastAlertsType[];
  handleCloseAlerts: (id: number) => void;
  handleAddAlerts: (alert: CustomToastAlertsType) => void;
}

const defaultValue: CustomToastProviderContextType = {
  alerts: [],
  handleCloseAlerts: console.log,
  handleAddAlerts: console.log,
};

export const CustomToastContext = React.createContext(defaultValue);

interface CustomToastProviderProps {
  children: any;
}

const CustomToastProvider: React.FC<CustomToastProviderProps> = (props) => {
  const [alerts, setAlerts] = React.useState<CustomToastAlertsType[]>([]);

  const handleCloseAlerts = (id: number) => {
    setAlerts((prev) => prev.filter((item) => item.id !== id));
  };

  const handleAddAlerts = (alert: CustomToastAlertsType) => {
    setAlerts((prev) => prev.concat(alert));
  };

  const value = {
    alerts,
    handleCloseAlerts,
    handleAddAlerts,
  };

  return (
    <CustomToastContext.Provider value={value}>
      <CustomToast />
      {props.children}
    </CustomToastContext.Provider>
  );
};

export default CustomToastProvider;
