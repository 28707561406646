/** @format */

import AxiosInstance from './index';
import config from '../config/config';
import { OrganizationUserType } from '../types/OrganizationUsers';

export const fetchMappedList = async (limit: number, offset: number) => {
  const res = await AxiosInstance({
    method: 'GET',
    baseURL: config.authBaseUrl,
    url: `/users/all`,
    params: {
      limit,
      offset,
    },
  });

  return res.data || {};
};

export const addUsers = async (userdata: OrganizationUserType, signatureId?: any) => {
  const formData = new FormData();
  formData.append('userdata', JSON.stringify(userdata));
  formData.append('docSign', signatureId);
  const res = await AxiosInstance({
    method: 'post',

    baseURL: config.authBaseUrl,
    url: `users`,
    data: formData,

    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data || {};
};

export const updateUsersInfo = async (userId: string, userdata: OrganizationUserType, signatureId?: any) => {
  const formData = new FormData();
  formData.append('userdata', JSON.stringify(userdata));
  formData.append('docSign', signatureId);
  const res = await AxiosInstance({
    method: 'patch',
    baseURL: config.authBaseUrl,
    url: `users/${userId}/userInfo`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res.data || {};
};
