/** @format */

import Joi from 'joi';
const phoneNumberRegex = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const getStringRangeSchema = (errorMesgName: string, min: number, max: number) => {
  return Joi.string()
    .min(min)
    .max(max)
    .label(errorMesgName)
    .messages({
      'string.max': `${errorMesgName} should be maximum of ${max} characters`,
      'string.min': `${errorMesgName} should be minimium of ${min} characters`,
      'any.required': `${errorMesgName} is required`,
      'string.empty': `${errorMesgName} is required`,
      'string.base': `${errorMesgName} is required`,
    });
};

export const OrgSaveSchema = (mode: 'EDIT' | 'SAVE'): Joi.Schema => {
  return Joi.object({
    organization: Joi.object(),
    user: Joi.object(),
    userId: Joi.string(),

    adminRoles: Joi.alternatives().try(Joi.array().items(Joi.string()), Joi.allow(null)),
    organizationId: Joi.string(),
    status: Joi.string(),
    name: getStringRangeSchema('Name', 3, 50)
      .label('Name')
      .options({ presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden' }),

    mobile: getStringRangeSchema('Phone Number', 8, 20)
      .label('Phone Number')
      .options({
        presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden',
      })
      .pattern(phoneNumberRegex)
      .messages({
        'string.pattern.base': 'Invalid Phone Number',
        'any.required': 'Phone Number is required',
        'string.empty': 'Phone Number is required',
      }),

    email: Joi.string()
      .pattern(emailRegex)
      .label('Email')
      .options({
        presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden',
      })
      .messages({
        'string.max': 'Email should be less than 100 characters',
        'any.required': 'Email is required',
        'string.empty': 'Email is required',
        'string.pattern.base': 'Invalid Email',
      }),

    qualification: Joi.string()
      .options({
        presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden',
      })
      .messages({
        'any.required': 'Qualification is required',
        'string.empty': 'Qualification is required',
      }),

    owner: Joi.boolean()
      .label('Owner')
      .options({
        presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden',
      }),

    adminRolesFlag: Joi.boolean(),

    docSign: Joi.string().allow('null', '').optional(),
    registrationNumber: getStringRangeSchema('registrationNumber', 3, 50)
      .messages({
        'any.required': 'registrationNumber is required',
        'string.empty': 'registrationNumber is required',
      })
      .allow(null, '')
      .optional(),

    // .label('AdminRolesFlag')
    // .options({ presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden' }),

    productRoles: Joi.array()
      .items(
        Joi.object({
          productId: Joi.string().options({}),
          roles: Joi.array()
            .items(Joi.string())
            .label('Product Roles')
            .min(mode === 'SAVE' ? 1 : 0)
            .required(),
        }),
      )
      .options({
        presence: mode === 'EDIT' ? 'optional' : mode === 'SAVE' ? 'required' : 'forbidden',
      }),
  });
};
