/** @format */

import * as React from 'react';
import { fetchAppConfig } from '../services/app-config';

export interface AppConfigContextType {
  serverSupportedProductId: string;
}

const defaultValue: AppConfigContextType = {
  serverSupportedProductId: '',
};

export const AppConfigContext = React.createContext(defaultValue);

interface AppConfigProps {
  children: any;
}

const AppConfigProvider: React.FC<AppConfigProps> = (props) => {
  const [appConfig, setAppConfig] = React.useState<AppConfigContextType>({ serverSupportedProductId: '' });

  const getAppConfig = async () => {
    try {
      const config = await fetchAppConfig();
      setAppConfig(config);
    } catch (err) {
      console.error('setAppConfig error', err);
    }
  };
  // console.log('appConfig',appConfig)
  React.useEffect(() => {
    getAppConfig();
  }, []);

  const value = {
    ...appConfig,
  };

  return <AppConfigContext.Provider value={value}>{props.children}</AppConfigContext.Provider>;
};

export default AppConfigProvider;
