/** @format */

import axios from 'axios';
import CONFIG from '../config/config';

export default axios.create({
  baseURL: CONFIG.authBaseUrl,
  timeout: CONFIG.apiTimeout,
  withCredentials: false,
});
