/** @format */

import { RawAxiosRequestConfig } from 'axios';

import AxiosInstance from './index';

import config from '../config/config';
import { OrganizationListApiResponseType, OrganizationItemType, OrganizationProductsType, OrganizationAuditLogsListApiResponseType, OrganizaitonDevicesType } from '../types/organizations';

export const fetchAllOrganizations = async (limit: number, offset: number): Promise<OrganizationListApiResponseType> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: '/admin/users',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const addOrganization = async (payload: OrganizationItemType, logo: any) => {
  console.log('logo', logo);
  const orgData = {
    organizationCode: payload.domain,
    name: payload.name,
    address: payload.address,
    city: payload.city,
    country: payload.country,
    state: payload.state,
    timezone: payload.timezone,
    pincode: payload.pincode,
    latitude: payload.latitude,
    longitude: payload.longitude,
  };
  console.log('orgData', orgData);

  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'POST',
    url: '/organizations',
    data: {
      orgData,
      logo,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const updateOrganization = async (payload: OrganizationItemType, organizationid: string, logo: any) => {
  const orgData = {
    // name: payload.name,
    address: payload.address,
    city: payload.city,
    country: payload.country,
    state: payload.state,
    pincode: payload.pincode,
    latitude: payload.latitude,
    timezone: payload.timezone,
    longitude: payload.longitude,
  };
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'PATCH',
    url: `/organizations/${organizationid}`,
    data: {
      orgData,
      logo,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchOrganizations = async (limit: number, offset: number) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'GET',
    url: '/organizations',
    params: {
      limit,
      offset,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchOrganizationItem = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'GET',
    url: `/organizations/${organizationId}`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const deleteOrganization = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'DELETE',
    url: `/organizations/${organizationId}`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const checkIfDomainIsValid = async (organizationCode: string, cancelToken: any) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'GET',
    url: `/organizations/domain/${organizationCode}`,
    cancelToken,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchOrganizationDetails = async (organizationId: string): Promise<OrganizationItemType> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchAllOrgProductsByOrgId = async (organizationId: string): Promise<OrganizationProductsType[]> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/products`,
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const fetchAllSearchedUsers = async (searchParam: string, cancelToken: any): Promise<OrganizationProductsType[]> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: '/users/search',
    cancelToken: cancelToken.token,
    params: {
      searchParam,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const checkIfregistrationNumberIsValid = async (registrationNumber: string, cancelToken: any) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'GET',
    url: `/users/info/${registrationNumber}`,
    cancelToken,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const checkIfEmailPhoneIsValid = async (searchParam: string, cancelToken: any): Promise<OrganizationProductsType[]> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: '/users/SearchEmailPhone',
    cancelToken: cancelToken.token,
    params: {
      searchParam,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const deleteOrgProductsById = async (organizationId: string, productId: string) => {
  const params: RawAxiosRequestConfig = {
    method: 'delete',
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/products/${productId}`,
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const activateOrgProductsById = async (organizationId: string, productId: string) => {
  const params: RawAxiosRequestConfig = {
    method: 'patch',
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/products/${productId}/activate`,
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const searchOrganizationDetails = async (searchkey: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/search/${searchkey}`,
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const addOrgProductsById = async (organizationId: string, productId: string) => {
  const params: RawAxiosRequestConfig = {
    method: 'post',
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/products`,
    data: {
      productId,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const fetchOrganizationAuditLogsById = async (organizationId: string, limit: number, offset: number): Promise<OrganizationAuditLogsListApiResponseType> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/audit/logs`,
    params: {
      limit,
      offset,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const addOrgAssetsById = async (organizationId: string, deviceId: string) => {
  const params: RawAxiosRequestConfig = {
    method: 'post',
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/devices`,
    data: {
      deviceId,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const deleteOrgAssetsById = async (organizationId: string, deviceId: string) => {
  const params: RawAxiosRequestConfig = {
    method: 'delete',
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/devices/${deviceId}`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const fetchAllOrgAssetsByOrgId = async (organizationId: string, limit: number, offset: number, deviceType: string): Promise<OrganizaitonDevicesType[]> => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/devices`,
    params: {
      limit,
      offset,
      deviceType,
    },
  };

  const res = await AxiosInstance(params);
  return res.data || [];
};

export const deleteOrganizationPortalData = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/portal/data`,
    method: 'DELETE',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const getAllexportOrganizationPortalData = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/portal/data/export/requests`,
    method: 'GET',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const createRequestTOexportAllOrganizationPortalData = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organizations/${organizationId}/portal/data/export`,
    method: 'POST',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const getAllOrganizationTemplates = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organization/${organizationId}/templates/all`,
    method: 'get',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const getListOrganizationTemplates = async (organizationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organization/${organizationId}/templates/list`,
    method: 'get',
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const createNewOrganizationTemplate = async (organizationId: string, payload: any) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organization/${organizationId}/templates`,
    method: 'POST',
    data: payload,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const updateOrganizationTemplate = async (organizationId: string, tempalteId: string, payload: any) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    url: `/organization/${organizationId}/templates/${tempalteId}`,
    method: 'PATCH',
    data: payload,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const updateOrganizationTemplateActive = async (organzationId: string, templateId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'PATCH',
    url: `organization/${organzationId}/templates/${templateId}/active`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};

export const deleteOrganizationTemplateSelection = async (organzationId: string) => {
  const params: RawAxiosRequestConfig = {
    baseURL: config.authBaseUrl,
    method: 'DELETE',
    url: `organization/${organzationId}/templates`,
  };

  const res = await AxiosInstance(params);
  return res.data || {};
};
