/** @format */

import { OptionsOrGroups } from 'react-select';
import { DropdownOptionType } from '../types/CustomForm.types';

export const QUALIFICATION_OPTIONS: OptionsOrGroups<DropdownOptionType, any> = [
  { label: 'Engineering', value: 'Engineering' },
  { label: 'Medicine (MBBS)', value: 'MBBS' },
  { label: 'Computer Science', value: 'Computer Science' },
  { label: 'Pharmacy', value: 'Pharmacy' },
  { label: 'Nursing', value: 'Nursing' },
  { label: 'PharmD (Doctor of Pharmacy)', value: 'PharmD' },
  { label: 'MD (Doctor of Medicine)', value: 'MD' },
];
